<div class="track-orders" [class]="expanded ? 'expanded' : ''">
    <div class="track-header" (click)="expanded = !expanded">
        <p>
            <span><b>{{data.pharmacy.displayName}}</b></span>
        </p>
        <img src="/assets/vectors/expand.svg" alt="">
    </div>
    <form #trackingForm="ngForm" (ngSubmit)="submitTrackingNumber(trackingForm)" class="track-form" novalidate
        autocomplete="off">
        <div>
            <input type="checkbox" name="selectAll" id="selectAll" (click)="setAllSelected($event)"
                [checked]="allSelected || selectedOrders.length === data.orderItems.length">
            <label for="selectAll" [style.margin-left]="'0.5rem'">Select All</label>
        </div>
        <div [style.display]="'flex'" [style.column-gap]="'1rem'">
            <input type="text" class="form-control" [(ngModel)]="trackingNumber" name="trackingNumber"
                placeholder="Add tracking number" required>
            <button class="btn btn-primary" [disabled]="trackingForm.invalid" type="submit">Submit Tracking
                Number</button>
        </div>
    </form>
    <div class="track-content">
        <div *ngFor="let item of data.orderItems" class="order-item">
            <input type="checkbox" name="check1" [id]="item.id" [checked]="selectedOrders.includes(item)"
                (change)="updateSelectedOrders($event, item)">
            <label class="order-details">
                <p>
                    <span><b>#{{item.id}}: </b></span>
                    <ng-container *ngIf="!item.parentOrderItemId && !item.isRepeatOrder; else hasParent">
                        <span><b>{{item.patient.displayName}}</b></span>
                        consulted by
                        <span *ngIf="item.consultationDuration === 'IN_PERSON_MINUTE_00'; else regularOrder">
                            <b>{{item.prescribingPharmacist.displayName}}</b>
                        </span>
                        <ng-template #regularOrder>
                            <span>
                                <b>{{item.doctor.displayName}}</b>
                            </span>
                        </ng-template>
                        on
                        <span><b>{{item.appointment.datetime | date : 'dd-MMM-yyyy HH:mm'}}</b></span>
                    </ng-container>
                    <ng-template #hasParent>
                        Repeat order by
                        <span><b>{{item.patient.displayName}}</b></span>
                        for consultation
                        <span><b>#{{item.parentOrderItemId}} </b></span>
                    </ng-template>
                </p>
                <div class="row" style="margin-top: 15px">
                  <div class="col-md-6">
                    <div class="prescription-image-container">
                      <div>
                        <img [src]="item.files[0].url" alt="" (click)="handleImagePreview($event, item.files[0].url)">
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
<!--                    <div class="notes-content prescription-margin block-element" style="text-align: center;">-->
<!--                        <div *ngIf="!!item" class="btn btn-default btn-lg" style="padding: 20px" (click)="openDigitalPrescription(item)">-->
<!--                          Prescription-->
<!--                        </div>-->
<!--                    </div>-->
                    <div class="content">
                      <div>
                        <h4 class="header">
                          Prescription
                        </h4>
                      </div>
                      <div class="modal-body">
                        <ng-container>
                          <div *ngFor="let medicine of item.medication" class="recommendations">
                            <table class="table">
                              <tbody>
                              <tr>
                                <th>
                                  <p>Date</p>
                                </th>
                                <th>{{ 'N/A' }}</th>
                              </tr>
                              <tr>
                                <th>
                                  <p>Type</p>
                                </th>
                                <th>Cannabis</th>
                              </tr>
                              <tr>
                                <th colspan="2">
                                  <p>Basic details</p>
                                </th>
                              </tr>
                              <tr>
                                <td>Category</td>
                                <td>{{medicine.formulary.category}}</td>
                              </tr>
                              <tr>
                                <td>Route of admin</td>
                                <td>{{medicine.formulary.administrationRoute}}</td>
                              </tr>
                              <tr>
                                <th colspan="2">
                                  <p>Patient preferences</p>
                                </th>
                              </tr>
                              <tr>
                                <td>Brand</td>
                                <td>{{medicine.formulary.brandName}}</td>
                              </tr>
                              <tr>
                                <td>Strain</td>
                                <td>{{medicine.formulary.strain}}</td>
                              </tr>
                              <tr>
                                <td>Manufacturer</td>
                                <td>{{medicine.formulary.manufacture}}</td>
                              </tr>
                              <tr>
                                <th>
                                  <p>Medication</p>
                                </th>
                                <th>{{medicine.formulary.productName}}</th>
                              </tr>
                              <tr>
                                <th>
                                  <p>Quantity</p>
                                </th>
                                <th>{{medicine.quantity}}</th>
                              </tr>
                              <tr>
                                <th colspan="2">
                                  <p>Additional comments</p>
                                </th>
                              </tr>
                              <tr>
                                <td colspan="2">
                                  <p>{{item.additionalNote}}</p>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
            </label>

        </div>
    </div>
</div>

<div bsModal #previewImgModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="previewImgModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">Prescription preview</h4>
      </div>
      <div class="modal-body">
        <div class="img-preview">
          <img [src]="selectedImage" alt="">
        </div>
      </div>
    </div>
  </div>
</div>

<div bsModal #digitalPrescription="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <ng-container *ngIf="selectedDigitalPrescription !== undefined">
    <app-prescription-modal [prescription]="selectedDigitalPrescription.medication" [pinkPrescriptionURL]="null" [creationDate]="selectedDigitalPrescription.creationDate"
                            (hideModal)="digitalPrescription.hide()">
    </app-prescription-modal>
  </ng-container>
</div>
