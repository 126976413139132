<div class="container">
    <h1 class="brand-1">Add Tracking numbers</h1>

    <div *ngIf="pharmacyOrders === undefined; else dataFetched" class="loading-container">
        <i class="fa fa-spinner fa-spin" style="font-size: 2rem;"></i>
    </div>

    <ng-template #dataFetched>
        <div class="track-orders-container">
            <app-track-orders-item *ngFor="let item of pharmacyOrders" [data]="item" (fetchData)="fetchData()"></app-track-orders-item>
        </div>
    </ng-template>
</div>